import React, { useEffect, useState, useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { API, graphqlOperation } from "aws-amplify"
import CustomAuth from "../components/custom-auth"
import { UserContext } from "../provider/usercontextprovider"
import { getUserIdentificationKey } from "../graphql/queries"
import { createUserIdentificationKey } from '../graphql/mutations';
import * as alertStyles from '../alert.module.css'

const UserQRCode = () => {

    const [userContext, setUserContext] = useContext(UserContext);
    const [qrCode, setQRCode] = useState({});
    const [showWarning, setShowWarning] = useState(false);
    const [loading, setLoading] = useState(true);

    async function loadQRCode() {
        const result = await API.graphql(graphqlOperation(getUserIdentificationKey, {
            owner: userContext.username
        }));
        console.log(result);
        if (result.data.getUserIdentificationKey) {
            setQRCode(result.data.getUserIdentificationKey);
        } else {
            //show some type of error page?
        }
        setLoading(false);
    }

    async function revokeQRCode() {
        const result = await API.graphql(graphqlOperation(createUserIdentificationKey));
        setShowWarning(false);
        console.log(result);
        if (result.data.createUserIdentificationKey) {
            setQRCode(result.data.createUserIdentificationKey);
        } else {
            //show some type of error page?
        }
    }

    useEffect(() => {
        setLoading(true)
        setQRCode({})
        if (userContext.username) {
            loadQRCode()
        }
    }, [userContext.username])

    const clsName = showWarning ? 'block' : 'none'
    return (
            <CustomAuth>
                <SEO title="Identification Key" />
                {!loading
                    ? <div>
                        {qrCode.qrcode ? 
                            <>
                                <div style={{ justifyContent: "center", display: "flex" }}>
                                    <img style={{marginBottom: "0"}} src={'data:image/png;base64, ' +  qrCode.qrcode} />
                                </div>
                                <div style={{ textAlign: "center", fontSize: "14px" }}><b>Key:</b> {qrCode.identificationKey}</div>
                                <div style={{ textAlign: "center", fontSize: "14px" }}><b>Created:</b> {qrCode.createdAt}</div>
                                <div style={{ textAlign: "center", marginBottom: "1rem" }}><a href="#" onClick={() => { setShowWarning(true) }}>Revoke</a></div>
                                <div className={`${alertStyles.alert} ${alertStyles.warn}`} style={{ textAlign: "center", display: clsName, fontSize: "14px" }}><b>Notice:</b>  Revoking a key that is associated with an external organization will require you to provide the updated identifier to the appropriate admin.  <a href="#" onClick={() => { revokeQRCode() }}>Continue</a></div>    
                            </>
                            :
                            <>
                                <div className={`${alertStyles.alert} ${alertStyles.info}`} style={{ textAlign: "center", fontSize: "14px" }}><b>Notice:</b>  User Identification Keys can be used as an identification token to a DetectWise enabled kiosk in leiu of providing a username enabling a touchless experience!  <a href="#" onClick={() => { revokeQRCode() }}>Create Identification Key</a></div>    
                            </>
                            
                    }
                        
                    </div>
                    : <div>Loading...</div>
                }
            </CustomAuth>
    )

}
export default UserQRCode